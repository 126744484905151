















































































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image, Popup } from "vant";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
    [Popup.name]: Popup,
  },
})
export default class PerHead extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public headImg!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  public isOrder!: boolean;

  @Prop({
    type: String,
    default: "",
  })
  public placeName!: string; //场馆名称
  @Prop({
    type: Number,
    default: -1,
  })
  public showPrice!: number; //价格
  @Prop({
    type: Number,
    default: null,
  })
  public showNum!: number; //购买数量
  @Prop({
    type: String,
    default: "",
  })
  public showName!: string; //产品名称

  @Prop({
    type: String,
    default: "",
  })
  public activityState!: string;

  @Prop({
    type: String,
    default: "",
  })
  public cityName!: string;

  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public noticeDtos!: Array<order.NoticeDto>; //购票须知

  @Prop({
    type: String,
    default: "",
  })
  public originalPrice!: string; //原价

  @Prop({
    type: String,
    default: "",
  })
  public limitName!: string; //原价

  @Prop({
    type: String,
    default: "",
  })
  public showTimeStr!: string; //活动时间区间
  @Prop({
    type: String,
    default: "",
  })
  public productSourceEnum!: string; //判断第三方
  @Prop({
    type: String,
    default: "",
  })
  public flashSalePrice!: string; //限时抢购价格

  isShow = false;
  //处理时间
  timeFilter(time?: number): string {
    if (!time) {
      return "";
    }
    let nowTime = "";
    if (this.isOrder) {
      nowTime = this.timestampFormat(time, "YYYY.MM.DD HH:mm");
    } else {
      nowTime = this.timestampFormat(time);
    }

    return nowTime;
  }
  close(): void {
    this.isShow = false;
  }
  //去详情
  goShowDetail(): void {
    this.$emit("goShowDetail");
  }

  /*
   * 限时抢购详情
   */
  goLimitDetail(): void {
    this.$emit("goLimitDetail");
  }

  /**
   * 点击购票须知
   */
  clickInfo(): void {
    if (this.$route.name == "per-order") {
      monitorEvent("OrderDetail_ClickInfo", "点击购票须知"); // 埋点：订单详情，点击购票须知
    }
    this.isShow = true;
  }
}
