













import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Row } from "vant";
@Component({
  components: {
    [Row.name]: Row,
  },
})
export default class FooterLine extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "我是有底线的哦",
  })
  public title!: string;
}
