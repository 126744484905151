export function monitorEvent(
  category: string,
  action: string,
  label?: string,
  value?: number
): void {
  const infoArray: Array<string | number> = ["_trackEvent", category, action];
  if (label) {
    infoArray.push(label);
  }
  if (value || value == 0) {
    infoArray.push(value);
  }
  const czc = (window as any)._czc;
  czc && czc.push(infoArray);
}
